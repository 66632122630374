/* You can add global styles to this file, and also import other style files */

@import "./scss/_variables.scss";

:root {
  box-sizing: border-box;
}

*,
::before,
::after {
  box-sizing: inherit;
}

header {
  display: block;
}

body {
  height: 100%;
  margin: 0;
  font-size: $font-size;
  line-height: 1.5rem;
  color: $fore-color;
  background-color: white;
  font-family: "source-han-sans-simplified-c", "Microsoft YaHei", sans-serif;
}

a,
a:hover,
a:active,
a:visited,
a:link,
a:focus {
  -webkit-tap-highlight-color: transparent !important;
  outline: none !important;
  text-decoration: none !important;
}

section {
  width: 100%;
  display: flex;
}

.container {
  display: block;
  width: 1200px;
  margin: 4.5rem auto;
  justify-content: center;
  align-items: center;
}

.center {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.right {
  float: right;
  padding-right: 2rem;
}

.bold {
  font-weight: bold;
}

.light {
  color: $light-color;
}

.dark {
  color: $fore-color;
}

.red {
  color: red;
}

.normal-color{
  color: $fore-color;
  background-color: white;
}

.reverse-color{
  color: white;
  background-color: $fore-color;
}

.border {
  border: $fore-color 1px solid;
}

.wait {
  position: relative;
  margin: 0rem auto;
  width: 800px;
  height: 10px;
  border-radius: 5px;
  background: repeating-linear-gradient(135deg, #f03355 0 10px, #ffa516 0 20px)
      0/0% no-repeat,
    repeating-linear-gradient(135deg, #ddd 0 10px, #eee 0 20px) 0/100%;
  animation: p3 2s infinite;
}

@keyframes p3 {
  100% {
    background-size: 100%;
  }
}

.divide {
  margin: 1rem 0 1rem 0;
}

.ant-divider-horizontal {
  margin: 8px 0;
}

.ant-tabs-tab-btn {
  color: $fore-color;
  font-size: 16px;
}

[nz-icon] {
  font-size:$font-size;
}

.ant-modal-title {
  font-size: $b-font;
  font-weight: bold;
  color: $fore-color;
}

em {
  font-weight: bold;
  font-style: normal;
}

.short {
  text-overflow: ellipsis;
  max-width: 10em;
  overflow: hidden;
  white-space: nowrap;
}

.cur {
  cursor: pointer;
}
