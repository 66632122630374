$width: 1200px;

$fore-color: #194261;
$light-color: #708BAE;
$light-blue: #3682ea;

$font-size: 14px;
$s-font: 12px;
$ss-font: 9px;
$b-font: 18px;
$bb-font: 20px;

$font-20: 20px;
$font-18: 18px;
$font-16: 16px;
$font-14: 14px;
$font-12: 12px;
$font-09: 9px;

$b-radius: 1rem;
$n-radius: 0.5rem;
$s-radius: 0.25rem;

$logo-size: 10rem;
// $log-url: "../../../assets/logos/dxlogo.png";
// $background-size: 100%;

// $log-url: "../../../assets/logos/logo.png";
// $background-size: 120%;

$log-url: "../../../assets/logos/hualulogo.png";
$background-size: 100%;

$log-vip: "../../../assets/vip/会员.png";
$log-vip2: "../../../assets/vip/会员2.png";

$log-vip-size: 20px;
